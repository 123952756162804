import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./index.css";

import Header from "./components/Header";
import About from "./routes/About";
import Config from "./routes/Config";
import Domains from "./routes/Domains";

class App extends React.Component {
  render() {
    return (
      <Router>
        <Header />
        <Switch>
          <Route exact path="/">
            <About />
          </Route>

          <Route path="/config">
            <Config />
          </Route>

          <Route path="/domains">
            <Domains />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
