import React from "react";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import "../index.css";
import Dropdown from "./Dropdown";

class Header extends React.Component {
  render() {
    return (
      <div>
        <div className="flex space-x-5 bg-gray-900 h-20">
          <Link
            to="/"
            className="py-5 pr-20 text-gray-400 text-3xl font-bold transition duration-300 hover:text-gray-300 pl-3 sm:pl-20"
          >
            jnr.cx
          </Link>
          <MediaQuery maxWidth={800}>
            <Dropdown />
          </MediaQuery>
          <MediaQuery minWidth={800}>
            <Link
              to="/config"
              className="py-5 text-gray-600 text-3xl font-bold transition duration-500 hover:text-gray-500"
            >
              config
            </Link>
            <Link
              to="/domains"
              className="py-5 text-gray-600 text-3xl font-bold transition duration-500 hover:text-gray-500"
            >
              domains
            </Link>
            <a
              className="py-5 text-gray-600 text-3xl font-bold transition duration-500 hover:text-gray-500"
              href="https://api.jnr.cx/docs"
              target="_blank"
              rel="noreferrer"
            >
              docs
            </a>
            <a
              className=" py-5 text-gray-600 text-3xl font-bold transition duration-500 hover:text-gray-500"
              href="https://discord.gg/wrgUcqQaTb"
              target="_blank"
              rel="noreferrer"
            >
              discord
            </a>
          </MediaQuery>
        </div>
      </div>
    );
  }
}

export default Header;
