import React from "react";
// import { default as axios } from "axios";
import "../index.css";

class Config extends React.Component {
  constructor() {
    super();
    this.state = {
      embed: false,
      domainList: "",
      invisible: false,
      show: true,
      title: "",
      description: "",
    };
    this.handleEmbed = this.handleEmbed.bind(this);
    this.handleInvisible = this.handleInvisible.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleTitle = this.handleTitle.bind(this);
    this.handleDescription = this.handleDescription.bind(this);
    this.handleDomains = this.handleDomains.bind(this);
  }

  // async componentDidMount() {
  //   const data = await axios("https://api.jnr.cx/domains");
  //   this.setState({
  //     domains: data.data.domains,
  //   });
  // }

  handleEmbed(checked) {
    this.setState({ embed: checked });
  }
  handleInvisible(checked) {
    this.setState({ invisible: checked });
  }
  handleShow(checked) {
    this.setState({ show: checked });
  }
  handleTitle(event) {
    this.setState({ title: event.target.value });
  }
  handleDescription(event) {
    this.setState({ description: event.target.value });
  }
  handleDomains(event) {
    this.setState({ domainList: event.target.value });
  }
  addDomain(event) {}

  render() {
    return (
      <div className="pt-20 pl-20">
        <p className="text-gray-300 text-3xl font-semibold flex-wrap">
          config creator
        </p>
        {/* <span className="text-gray-300 ml-96 pl-36 text-3xl font-semibold">
          add a domain
        </span>
        <form onSubmit={this.addDomain}>
          <input
            type="text"
            className="mt-1 ml-96 block w-64 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          ></input>
          <input type="submit" value="Submit" />
        </form> */}
        <br />
        <label className="inline-flex items-center pt-5">
          <input
            type="checkbox"
            className="transition duration-300 rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50"
            checked={this.state.embed}
            onChange={() => this.handleEmbed(!this.state.embed)}
          ></input>
          <span className="px-3 text-gray-300">enable embed</span>
        </label>
        <label className="inline-flex items-center pt-5">
          <input
            type="checkbox"
            className="transition duration-300 rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50"
            checked={this.state.show}
            onChange={() => this.handleShow(!this.state.show)}
          ></input>
          <span className="px-3 text-gray-300">show link</span>
        </label>
        <label className="inline-flex items-center pt-5">
          <input
            type="checkbox"
            className="transition duration-300 rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50"
            checked={this.state.invisible}
            onChange={() => this.handleInvisible(!this.state.invisible)}
          ></input>
          <span className="px-3 text-gray-300">invisible url</span>
        </label>

        <p className="text-gray-200 font-sm pt-5">embed title</p>
        <input
          type="text"
          className="mt-1 block w-64 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          value={this.state.title}
          onChange={this.handleTitle}
        ></input>
        <p className="text-gray-200 font-sm pt-5">embed description</p>
        <input
          type="text"
          className="mt-1 w-96 pb-20 block w-64 rounded-md border-gray-300 shadow-sm hover:border-indigo-300 hover:ring hover:ring-indigo-200 hover:ring-opacity-50 mb-5"
          value={this.state.description}
          onChange={this.handleDescription}
        ></input>
        <span className="text-gray-200 font-sm pt-5">domains</span>
        <input
          type="text"
          className="mt-1 block w-64 mb-16 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="r:jnr.cx-f:google.com"
          value={this.state.domainList}
          onChange={this.handleDomains}
        ></input>
        <a
          className="bg-gray-600 rounded-lg p-5 text-gray-200 transition duration-300 hover:bg-gray-700"
          href={`https://api.jnr.cx/config?e=${this.state.embed}?d=${this.state.domainList}?s=${this.state.show}?i=${this.state.invisible}?t=${this.state.title}?d=${this.state.description}`}
        >
          download config
        </a>
      </div>
    );
  }
}

export default Config;
