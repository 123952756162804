import React from "react";
import "../index.css";

class Domain extends React.Component {
  render() {
    return (
      <div className="text-gray-200 text-xl font-medium pl-10 pt-3">
        {this.props.name}
      </div>
    );
  }
}

export default Domain;
