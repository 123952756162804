import React from "react";
import { default as axios } from "axios";
import "../index.css";
import Domain from "../components/Domain";

class Domains extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      domains: null,
    };
  }

  async componentDidMount() {
    const data = await axios("https://api.jnr.cx/domains");

    this.setState({
      loading: false,
      domains: data.data.domains,
    });
  }

  render() {
    const Domains = this.state.loading ? (
      <Domain name="Loading" />
    ) : (
      this.state.domains.map((d) => <Domain name={d} />)
    );
    return (
      <div className="pt-20 pl-20">
        <p className="text-gray-300 text-3xl font-semibold flex-wrap">
          domains
        </p>
        <div className="flex flex-col">
          {Domains}
          <div className="mt-8"></div>
        </div>
      </div>
    );
  }
}

export default Domains;
