import React from "react";
import "../index.css";

class About extends React.Component {
  render() {
    return (
      <div>
        {" "}
        <div className="pt-20 pl-20">
          <p className="text-gray-200 text-2xl flex-wrap">
            image host and url shortener with no tracking and fast responses
            worldwide
          </p>
        </div>
        <div className="bg-gray-900 mt-20 pt-96 pr-96">
          <div className="pt-36"></div>
        </div>
      </div>
    );
  }
}

export default About;
